import { AuthenticationResult, Configuration, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import AuthInterface from "./AuthInterface";
import env from "@/env";
import logger from "@/lib/logger";
import { Profile } from "@/types/profile";
import Auth from "./msal";

const AZURE_CLIENT_ID = env.AZURE_CLIENT_ID;
const AZURE_AUTHORITY = "https://login.microsoftonline.com/720b637a-655a-40cf-816a-f22f40755c2c";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
const msalConfig: Configuration = {
    auth: {
        clientId: AZURE_CLIENT_ID ?? "",
        authority: AZURE_AUTHORITY ?? "",
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        return console.error(message);
                    case LogLevel.Info:
                        return console.info(message);
                    case LogLevel.Verbose:
                        return console.log(message);
                    case LogLevel.Warning:
                        return console.warn(message);
                }
            },
        },
    },
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me/",
    graphMyPictureEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
    graphUserPictureEndpoint: (userPrincipleName: string) => `https://graph.microsoft.com/v1.0/users/${userPrincipleName}/photo/$value`,
};

const msalInstance = new PublicClientApplication(msalConfig);

export default class Msal implements AuthInterface {
    // only used by Workload component; needs to be removed
    clientId = AZURE_CLIENT_ID!;

    private async getAuthResult() {
        await msalInstance.initialize();
        return msalInstance.handleRedirectPromise().then(async (response) => {
            if (!response) {
                if (msalInstance.getAllAccounts().length === 0) {
                    await msalInstance.loginRedirect();
                } else {
                    const loginRequestConf = {
                        scopes: ["User.Read"],
                        account: msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0],
                    };

                    return msalInstance.acquireTokenSilent(loginRequestConf).catch((error) => {
                        log.error(error);
                        msalInstance.loginRedirect(loginRequestConf);
                    });
                }
            }

            return response;
        });
    }

    async getUserProfile() {
        try {
            const token = await this.getToken(["User.Read"]);
            const headers = new Headers();
            const bearer = `Bearer ${token}`;

            headers.append("Authorization", bearer);

            const options = {
                method: "GET",
                headers: headers,
            };

            const user = await fetch(graphConfig.graphMeEndpoint, options);

            if (!user.ok) {
                throw new Error("Failed to fetch user profile");
            }

            const profile = await user.json();
            const picture = await this.getProfilePicture(profile.mail);
            return {
                ...profile,
                picture,
            };
        } catch (error) {
            log.error(error);
            return null;
        }
    }

    async getIdToken() {
        const r = await this.getAuthResult();
        if (r) {
            return r.idToken;
        }
        return null;
    }

    async login(): Promise<string | null> {
        const r = await this.getAuthResult();
        if (r) {
            return this.getToken();
        }
        return null;
    }

    async logout() {
        msalInstance.logoutRedirect();
    }

    /**
     * Scopes you add here will be prompted for user consent during sign-in.
     * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
     * For more information about OIDC scopes, visit:
     * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
     */
    async getToken(scopes?: string[]) {
        const loginRequestConf = {
            scopes: scopes ?? ["api://1cea2006-5321-4bdb-a8e0-6668880349bb/profile.read"],
            account: msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0],
        };
        const { accessToken } = await msalInstance.acquireTokenSilent(loginRequestConf);
        return accessToken;
    }

    async getProfilePicture(upn?: string) {
        const headers = new Headers();
        const token = await this.getToken(["User.Read"]);
        headers.append("Authorization", `Bearer ${token}`);
        const options = {
            method: "GET",
            headers,
        };

        return fetch(upn ? graphConfig.graphUserPictureEndpoint(upn) : graphConfig.graphMyPictureEndpoint, options)
            .then((response) => {
                if (!response.ok) {
                    return undefined;
                }
                return response.blob();
            })
            .then((blob) => {
                return blob && URL.createObjectURL(blob);
            })
            .catch((error) => {
                log.error(error);
                return undefined;
            });
    }
}
