import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "@/api/storage";
import { useCombobox } from "downshift";
import { generateHtmlClass } from "@/lib/utils";
import Button from "@ingka/button";
import { t } from "i18next";
import historyIcon from "@ingka/ssr-icon/paths/history";
import globeIcon from "@ingka/ssr-icon/paths/globe";
import "./DevTools.scss";
import { MQ_MOBILE_BREAKPOINT, SKAPA_PREFIX } from "@/lib/constants";
import { useQueryClient } from "react-query";
import useServerRequest from "@/hooks/useServerRequest";
import useProfile from "@/hooks/useProfile";
import { User, Role } from "@packages/common/user";
import { endpoints } from "@/api/http";
import Select, { Option } from "@ingka/select";
import Hyperlink from "@ingka/hyperlink";
import { levenshtein } from "@/lib/strings";
import useMediaQuery from "@/hooks/useMediaQuery";

const DEV_STORAGE_KEY = "testAs";
const MAX_USERS_TO_SHOW = 30;

// role key used in translations
const getRoleKey = (user: User, fallback = "") => (user.role ? `user.role.${user.role}` : fallback);

const getUserKeywords = (user: User): string[] => {
    const keywords = [String(user.id), user.name, user.email, t(getRoleKey(user))];

    /*
    if (user.department.division) {
        keywords.push(user.department.division.name);
    }

    if (user.department) {
        keywords.push(user.department.name);
    }
        */

    if (user.costCenter) {
        keywords.push(user.costCenter);
    }

    if (user.activities) {
        keywords.push(...user.activities.map((d) => d.name));
    }

    return keywords;
};

const findClosestMatch = (keywords: string[], input: string): string | null => {
    keywords = keywords.map((v) => v.toLowerCase());
    input = input.toLowerCase();

    let minDistance = Number.MAX_SAFE_INTEGER;
    let closestMatch = null;
    for (const keyword of keywords) {
        const distance = levenshtein(keyword, input);
        if (distance < minDistance) {
            minDistance = distance;
            closestMatch = keyword;
        }
    }

    return closestMatch;
};

// filtering function for user autocomplete
const optionFilter = (input = "") => {
    const normalize = (s: string) => s.toLowerCase().replace(/[\W_]+/g, "");
    const searchValues = input
        .split(/\s+/g)
        .filter((v) => v !== "")
        .map(normalize);
    const matchesInput = (k: string) => searchValues.every((v) => k.indexOf(v) >= 0);
    return (user: User) => {
        const keywords = getUserKeywords(user);
        return !searchValues.length || matchesInput(keywords.map((v) => normalize(v + "")).join(","));
    };
};

const DevTools = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const profile = useProfile();

    const [matchedUsers, setMatchedUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);
    const [selectedUnit, setSelectedUnit] = useState(profile!.department.division.unit);
    const [limit, setLimit] = useState(MAX_USERS_TO_SHOW);
    const [queryCacheSize, setQueryCacheSize] = useState(0);
    const queryCacheSizeRef = useRef(queryCacheSize);

    const isMobile = useMediaQuery((w) => w < MQ_MOBILE_BREAKPOINT);

    const { data: serverCacheKeys, refetch: refetchCacheKeys } = useServerRequest("dev/cache");
    const { data: units = [profile!.department.division.unit] } = useServerRequest("dev/units");
    const { data: users } = useServerRequest("dev/users", { unit: [selectedUnit.id] });

    // sort units by country, then type, then number
    units.sort((a, b) => {
        const v = `${a.country}.${a.type}`.localeCompare(`${b.country}.${b.type}`);
        if (v === 0) {
            return a.code !== b.code ? 1 : -1;
        }

        return v;
    });

    // sort user by role, then name
    users?.sort((a, b) => {
        if (a.role === b.role) {
            return a.name.localeCompare(b.name) > 0 ? 1 : -1;
        }

        return a.role > b.role ? 1 : -1;
    });

    useEffect(() => {
        const queryCache = queryClient.getQueryCache();

        // @note: queryCache.subscribe() should be used instead,
        // but it breaks modal components for some reason
        const intv = setInterval(() => {
            const relevantQs = queryCache.findAll().filter(({ state, queryKey }) => {
                const [path] = queryKey;
                return !(path as string).startsWith("dev/") && !state.isInvalidated;
            });

            if (queryCacheSizeRef.current !== relevantQs.length) {
                setQueryCacheSize(relevantQs.length);
                refetchCacheKeys();
            }
        }, 500);

        return () => clearInterval(intv);
    }, []);

    // sync ref
    useEffect(() => {
        queryCacheSizeRef.current = queryCacheSize;
    }, [queryCacheSize]);

    const reset = () => {
        storage.remove(DEV_STORAGE_KEY);
        setSelectedUser(null);
        navigate(0);
    };

    const flushCache = async () => {
        // client cache (react-query)
        await queryClient.invalidateQueries();
        log("flush");
        // server cache
        const counts = new Map<string, number>();

        if (serverCacheKeys) {
            for (const k of serverCacheKeys) {
                const parts = k.split("/");
                parts.pop();
                const ns = parts.join("/") + "/*";
                counts.set(ns, counts.has(ns) ? counts.get(ns)! + 1 : 1);
            }
        }

        log.info("server cache", Object.fromEntries(counts));
        await endpoints.request("dev/cache", {}, { method: "DELETE" });
        refetchCacheKeys();
    };

    // fetch selected user from storage
    useEffect(() => {
        setLimit(MAX_USERS_TO_SHOW);
        if (!users?.length) {
            setMatchedUsers([]);
            return;
        }

        storage.get<string>(DEV_STORAGE_KEY).then((savedId) => {
            setMatchedUsers(users);
            const [match] = users.filter((user) => savedId && user.id === savedId);
            if (match) {
                setSelectedUser(match);
            }
        });
    }, [users]);

    // user autocomplete def
    const { isOpen, openMenu, getMenuProps, getInputProps, getComboboxProps, getItemProps, inputValue, setInputValue } = useCombobox({
        onInputValueChange: ({ inputValue }) => setMatchedUsers(users?.filter(optionFilter(inputValue)) ?? []),
        onSelectedItemChange: ({ selectedItem: user }) => {
            if (!user) {
                return;
            }

            storage.set(DEV_STORAGE_KEY, user.id);
            setSelectedUser(user);
            navigate(0);
        },

        itemToString: (user) => (user ? user.id.toString() : ""),
        items: matchedUsers,
        selectedItem: selectedUser,
    });

    const onDashboard = location.pathname.includes("/dashboard");

    // limit visible users for performance reasons (too many elements slow down the DOM)
    const visibleUsers = matchedUsers.slice(0, Math.min(matchedUsers.length, limit));

    // only do keyword-input distance calculation if user filter has no matches
    const closestMatch = matchedUsers.length ? null : findClosestMatch(users?.map(getUserKeywords).flatMap((v) => v) ?? [], inputValue);

    return (
        <div className="dev-tools">
            <Select
                prefix={SKAPA_PREFIX}
                id="devtools-unit-select"
                data-test="devtools-unit-select"
                ssrIcon={globeIcon}
                className="font-size-12"
                value={selectedUnit.id}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedUnit(units.find((u) => u.id === e.target.value)!)}
                hintText=""
            >
                {units.map(({ id, country, type, code, name }) => (
                    <Option key={id} value={id} name={`${country}.${type}.${code} - ${name}`} />
                ))}
            </Select>

            <div className="test-as-user">
                <div className="user-input" {...getComboboxProps()}>
                    <input placeholder="Test as user" {...getInputProps({ onFocus: openMenu })} data-test="devtools-search-input" />
                    <Button
                        disabled={selectedUser === null}
                        type="danger"
                        small
                        iconOnly={isMobile}
                        text={isMobile ? "R" : "Reset"}
                        prefix={SKAPA_PREFIX}
                        onClick={() => reset()}
                    />
                </div>
                <div className={generateHtmlClass("user-input-options", { active: isOpen })} {...getMenuProps()}>
                    {isOpen && (
                        <ul data-test="devtools-search-results">
                            {visibleUsers.map((user, index) => (
                                <li key={index} {...getItemProps({ item: user, index })}>
                                    <div
                                        className="id"
                                        title={user.id.toString()}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            navigator.clipboard.writeText(user.id.toString());
                                        }}
                                    >
                                        {user.id}
                                    </div>
                                    <div className="details">
                                        <p title={user.name}>
                                            <b>{user.name}</b>
                                        </p>
                                        <p>
                                            {/*
                                            <dfn>{user.department.division.name}</dfn>
                                            <dfn>{user.department.name}</dfn>
                                            */}
                                        </p>

                                        <p title={user.email}>
                                            <i className="email">{user.email}</i>
                                        </p>
                                    </div>
                                    <div className="role">{t<string>(getRoleKey(user, "N/A"))}</div>
                                </li>
                            ))}
                            {matchedUsers.length > visibleUsers.length && (
                                <li>
                                    <Button small prefix={SKAPA_PREFIX} onClick={() => setLimit(limit + MAX_USERS_TO_SHOW)}>
                                        Show more ({matchedUsers.length - visibleUsers.length} remaining)
                                    </Button>
                                    or refine the filter for best match...
                                </li>
                            )}

                            {matchedUsers.length === 0 && (
                                <li>
                                    {users?.length ? "No matches." : "This unit has no active users."}
                                    {closestMatch && (
                                        <Hyperlink onClick={() => setInputValue(closestMatch)}>
                                            Did you mean <b>{closestMatch}</b>?
                                        </Hyperlink>
                                    )}
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </div>

            {onDashboard && (
                <Button
                    onClick={() => navigate({ pathname: "/coworker" }, { replace: true })}
                    type="emphasised"
                    small
                    prefix={SKAPA_PREFIX}
                    text={isMobile ? "C" : "Coworker"}
                />
            )}

            {!onDashboard && (
                <Button
                    disabled={profile!.role === Role.COWORKER}
                    onClick={() => navigate({ pathname: "/dashboard" }, { replace: true })}
                    type="emphasised"
                    small
                    prefix={SKAPA_PREFIX}
                    text={isMobile ? "D" : "Dashboard"}
                />
            )}

            <Button
                onClick={() => flushCache()}
                type="emphasised"
                small
                prefix={SKAPA_PREFIX}
                ssrIcon={historyIcon}
                iconPosition="leading"
                iconOnly={isMobile}
                text={`${queryCacheSize} rq + ${serverCacheKeys?.length || 0} srv`}
            />
        </div>
    );
};

export default DevTools;
