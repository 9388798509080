export default class Auth {
    async login(): Promise<string | null> {
        // do nothing
        return "fake-token";
    }

    async logout() {
        // do nothing
    }

    async getToken(_force = false): Promise<string> {
        return "fake-token";
    }

    async getUserProfile() {
        return {};
    }

    async getIdToken(): Promise<string> {
        return "fake-id-token";
    }

    async getProfile(): Promise<any> {
        return {
            givenName: "e2e",
            surname: "tester",
            displayName: "e2e tester",
        };
    }

    async getProfilePicture(): Promise<string | undefined> {
        return;
    }
}
