/**
 *  Profile interface
 *
 *
 */

import { User, Role, Department, Unit, Division } from "@packages/common/user";

export enum Permission {
    UPLOAD_AVAIL,
    EDIT_FREEZING_PERIOD,
    EDIT_WORKLOADS,
    ADD_USERS,
    EDIT_USER_ACTIVITIES,
    EDIT_USER_ROLE,
    DOWNLOAD_AVAIL,
    REPORTS,
    SWITCH_APP,
    OPP,
    NOTIFICATIONS,
    TEAM_FILTER,
    CREATE_AVAIL,
    ONBOARDING,
    CONTACT_UPPS,
    MGR_MANUAL,
    CREATE_AVAILABILITY_CONDITIONS,
    TOGGLE_AVAILABILITY_CONDITIONS,
}

const CPPS_PERMISSIONS = [
    Permission.UPLOAD_AVAIL,
    Permission.EDIT_WORKLOADS,
    Permission.EDIT_USER_ACTIVITIES,
    Permission.EDIT_USER_ROLE,
    Permission.DOWNLOAD_AVAIL,
    Permission.SWITCH_APP,
    Permission.OPP,
    Permission.MGR_MANUAL,
    Permission.REPORTS,
];

const UPPS_PERMISSIONS = [
    Permission.UPLOAD_AVAIL,
    Permission.EDIT_WORKLOADS,
    Permission.EDIT_FREEZING_PERIOD,
    Permission.EDIT_USER_ACTIVITIES,
    Permission.EDIT_USER_ROLE,
    Permission.NOTIFICATIONS,
    Permission.DOWNLOAD_AVAIL,
    Permission.SWITCH_APP,
    Permission.OPP,
    Permission.MGR_MANUAL,
    Permission.REPORTS,
    Permission.TOGGLE_AVAILABILITY_CONDITIONS,
    Permission.CREATE_AVAIL,
];

const MGR_DEPT_PERMISSIONS = [
    Permission.NOTIFICATIONS,
    Permission.SWITCH_APP,
    Permission.OPP,
    Permission.MGR_MANUAL,
    Permission.REPORTS,
    Permission.CREATE_AVAIL,
    Permission.EDIT_USER_ACTIVITIES,
    Permission.EDIT_WORKLOADS,
];

// same as dept manager + 2 extra
const SHOPKEEPER_PERMISSIONS = [Permission.CREATE_AVAIL, Permission.TEAM_FILTER, ...MGR_DEPT_PERMISSIONS];

const CW_PERMISSIONS = [Permission.CREATE_AVAIL, Permission.NOTIFICATIONS, Permission.ONBOARDING, Permission.CONTACT_UPPS];

const DEV_PERMISSIONS = [
    ...CPPS_PERMISSIONS,
    ...UPPS_PERMISSIONS,
    ...MGR_DEPT_PERMISSIONS,
    ...SHOPKEEPER_PERMISSIONS,
    ...CW_PERMISSIONS,
    Permission.ADD_USERS,
    Permission.CREATE_AVAILABILITY_CONDITIONS,
];

export const PERMISSION_MAP: Record<Role, Permission[]> = {
    [Role.DEV]: DEV_PERMISSIONS,
    [Role.CPPS]: CPPS_PERMISSIONS,
    [Role.UPPS]: UPPS_PERMISSIONS,
    [Role.SHOPKEEPER]: SHOPKEEPER_PERMISSIONS,
    [Role.MGR_DEPT]: MGR_DEPT_PERMISSIONS,
    [Role.COWORKER]: CW_PERMISSIONS,
    [Role.INTEGRATION]: [],
};

export interface Profile extends User {
    pictureUrl?: string;
    developer?: string;

    department: Department;
}

// use ts declaration merging
export class Profile {
    has(feature: Permission) {
        return PERMISSION_MAP[this.role].includes(feature);
    }
}
